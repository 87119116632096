<template>
  <div
    ref="datePickerContainer"
    class="datePickerContainer relative"
  >
    <section class="d-flex w100">
      <div class="w100">
        <input
          ref="button"
          v-model="selected"
          maxlength="0"
          type="text"
          :style="inputStyling"
          :disabled="!noedit"
          data-lpignore="true"
          class="capital"
          @keydown.delete="(e) => e.preventDefault()"
          @click="showPicker"
          @keydown.enter="showPicker"
          @keydown.space="showPicker"
          @keydown.esc="onBlur"
        >
        <div
          ref="line"
          class="line"
        />
      </div>
      <button
        v-if="noedit"
        tabindex="-1"
        type="button"
        class="box center"
        :style="boxStyling"
        @click="showPicker"
      >
        <s-icon
          height="16"
          color="grey"
        >
          calendar-week
        </s-icon>
      </button>
    </section>

    <transition name="dropdown">
      <div
        v-if="show"
        v-hotkey="{ esc: onBlur }"
        class="datepicker noselect"
      >
        <FocusLoop>
          <div class="d-flex space-between capital">
            <div>
              {{ date.locale(lang).format("MMM") }} {{ date.format("YYYY") }}
            </div>
            <div class="d-flex">
              <button
                type="button"
                class="center chevron mr-5"
                @click="decrement"
              >
                <s-icon color="white">
                  chevron-left
                </s-icon>
              </button>
              <button
                type="button"
                class="center chevron ml-5"
                @click="increment"
              >
                <s-icon color="white">
                  chevron-right
                </s-icon>
              </button>
            </div>
          </div>
          <div class="headings capital">
            <div
              v-for="item in daysOfWeek"
              :key="item.idx"
            >
              {{ item }}
            </div>
          </div>
          <div class="grid-container">
            <div
              v-for="item in monthRange"
              :key="item.idx"
              class="center grid-item"
            >
              <button
                v-if="item.month() + 1 == date.format('M')"
                class="opacity-60 today-oval center oval"
                type="button"
                :class="{
                  green: today == item.format('DD/MMM/YYYY'),
                  selected:
                    absDate.format('MMM D, YYYY') == item.format('MMM D, YYYY'),
                }"
                @click="selectDate(item)"
              >
                {{ item.format("D") }}
              </button>
            </div>
          </div>
        </FocusLoop>
      </div>
    </transition>
  </div>
</template>

<script>
import dayjs from "dayjs";
import localData from "dayjs/plugin/localeData";
import { mapState } from "vuex";
dayjs.extend(localData);
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

export default {
    props: ["height", "prefill", "noedit"],
    data() {
        return {
            monthRange: [],
            selected: null,
            show: false,
            absDate: null,
        };
    },
    methods: {
        generateCal(date) {
            this.monthRange = [];
            let day = date.startOf("month").startOf("week");
            for (let x = 0; x < 42; x++) {
                this.monthRange.push(day.add(x, "day"));
            }
        },
        increment() {
            this.date = this.date.add(1, "month");
            this.generateCal(this.date);
        },
        decrement() {
            this.date = this.date.subtract(1, "month");
            this.generateCal(this.date);
        },
        selectDate(val) {
            this.selected = val.locale(this.lang).format("ll");
            this.absDate = val;
            this.$emit("changeDate", val);
            this.show = false;
            this.onBlur();
            this.$refs.button.focus();
        },
        showPicker() {
            if (!this.noedit) return;
            this.show = !this.show;
            if (this.show) this.onFocus();
            if (!this.show) this.onBlur();
        },
        listen(event) {
            const el = this.$refs.datePickerContainer;
            if (!el.contains(event.target)) {
                this.show = false;
                this.onBlur();
            }
        },
        onFocus() {
            this.$refs.line.style.width = "100%";
        },
        onBlur() {
            this.show = false;
            this.$refs.line.style.width = "0";
        },
        isValidEvent(date) {
            let sTime = dayjs(this.datetime).format('hh:mm A"');
            let sDate = dayjs(date).format("MMM D, YYYY");
            let day = dayjs(sDate + " " + sTime, "MMM D, YYYY hh:mm A");
            if (!day.isSame(dayjs())) return !day.isBefore(dayjs());
        },
        init() {
            this.date = dayjs();
            this.today = dayjs().format("DD/MMM/YYYY");
            this.selected = dayjs().locale(this.lang).format("ll");
            this.absDate = dayjs();

            if (this.prefill) {
                this.selected = dayjs(this.prefill, "MMM D, YYYY");
                this.selected = this.selected.locale(this.lang).format("ll");
                this.date = dayjs(this.prefill, "MMM D, YYYY");
                this.absDate = dayjs(this.prefill, "MMM D, YYYY");
            }
            this.generateCal(this.date);

            if (!this.noedit) return;
            this.$emit("changeDate", dayjs(this.absDate));
        },
    },
    computed: {
        ...mapState("user", ["lang"]),
        ...mapState("schedule", ["datetime"]),
        inputStyling: function () {
            let ob = {};
            if (this.height) ob.height = this.height + "px";
            if (!this.noedit) ob.backgroundColor = "#00000000";
            if (this.show) ob.backgroundColor = "rgba(0, 0, 0, 0.45)";
            return ob;
        },
        boxStyling: function () {
            let ob = {};
            if (this.height) ob.height = this.height + "px";
            if (this.height) ob.minWidth = this.height + "px";
            if (this.show) ob.backgroundColor = "#000000";
            return ob;
        },
        isValidEventComp() {
            return !dayjs(this.datetime).isBefore(dayjs());
        },
        daysOfWeek() {
            return dayjs().locale(this.lang).localeData().weekdaysMin();
        },
    },
    watch: {
        $route() {
            this.init();
        },
        lang() {
            this.init();
        },
    },
    destroyed() {
        document.body.removeEventListener("click", this.listen);
    },
    mounted() {
        document.body.addEventListener("click", this.listen);
    },
    created() {
        this.init();
    },
};
</script>

<style scoped>
.box {
  min-width: 30px;
  height: 30px;
  background-color: #151b22;
  margin-left: 2px;
}

.datePickerContainer {
  width: 100%;
}

.datepicker {
  width: 290px;
  height: 320px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
  padding: 15px;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
}

.headings {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  opacity: 0.6;
  font-size: 10px;
  margin-top: 15px;
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  height: 235px;
  margin-top: 10px;
}

.line {
  width: 0;
  height: 1px;
  background-color: #409875;
  transition: all ease 0.2s;
}

.today-oval {
  height: 80%;
  width: 80%;
  border-radius: 50%;
}

.oval:hover,
.oval:focus {
  background-color: #3a4b5a;
  border-radius: 50%;
  height: 80%;
  width: 80%;
  opacity: 1;
}

.chevron:focus,
.chevron:active {
  background-color: #3a4b5a;
  border-radius: 50%;
}

.selected {
  background-color: #3a4b5a;
  border: 1px solid #409875;
  color: #56bc93;
  border-radius: 50%;
  height: 80%;
  width: 80%;
  font-weight: 400;
}

input {
  height: 30px;
  background-color: rgba(0, 0, 0, 0.35);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  caret-color: transparent;
  -webkit-appearance: none;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  pointer-events: auto;
}

input:disabled {
 cursor: default;
}

input:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

input:focus {
  background-color: rgba(0, 0, 0, 0.45);
  color: rgba(255, 255, 255, 0.95);
  font-size: 14px;
  outline: none;
}

input::selection {
  background: none;
}

@media only screen and (max-width: 576px) {
  .datepicker {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
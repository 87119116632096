<template>
  <s-modal>
    <form
      class="col gap-50"
      @submit.prevent="post"
    >
      <section class="header row space-between align-center">
        <span class="font-18">Maximum rooms exceeded</span>
        <button
          class="center"
          @click="close"
        >
          <s-icon color="grey">
            close
          </s-icon>
        </button>
      </section>
      <section class="body">
        <span>You have reach maximum amount of rooms for your account!</span>
      </section>
      <section class="footer row gap-20 justify-end">
        <s-btn
          type="button"
          height="36"
          @click.native="close"
        >
          {{ $t("modal.close") }}
        </s-btn>
      </section>
    </form>
  </s-modal>
</template>
  
<script>
export default {
    methods: {
        post(){},
        close() {
            this.$store.commit("modals/maxRooms", false);
        },
    },
};
</script>
  
  <style scoped>
  </style>
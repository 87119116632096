import { del, get, post } from "../assets/functions";
import store from "./index";
export default {
    namespaced: true,
    actions: {
        async activate(context, data) {
            return await post("/invitation/activate");
        },
        async getEmail(context) {
            let url = "/invitation/activate/76c158ab5528d31fb2db9cd997779a43d1a32675";
            return await get(url);
        },
        async vipActivate(context, data) {
            return await post("/vip/activate", data);
        },
        async contact(context, data) {
            return await post("/contact", data);
        },
        async invitation(context, data) {
            return await post("/invitation", data);
        },
        async vip(context, data) {
            return await post("/vip", data);
        },
        async login(context, data) {
            return await post("/login", data);
        },
        async invite(context, data) {
            return await get(data);
        },
        async room(context, data) {
            return await get(data);
        },
        async register(context, data) {
            return await post("/register", data);
        },
    },
};

<template>
  <div class="relative">
    <input
      id="cbx"
      :checked="check"
      class="inp-cbx"
      type="checkbox"
      disabled="disabled"
      @click="select"
    >
    <label
      class="cbx"
      for="cbx"
    >
      <span>
        <svg
          width="12px"
          height="10px"
          viewbox="0 0 12 10"
        >
          <polyline points="1.5 6 4.5 9 10.5 1" />
        </svg>
      </span>
      <span />
    </label>
  </div>
</template>

<script>
export default {
    name: "CheckBoxComponent",
    props: ["value"],
    data() {
        return {
            check: true,
        };
    },
    watch: {
        value() {
            this.check = this.value;
        },
    },
    beforeMount() {
        this.check = this.value;
    },
    methods: {
        select() {
            this.check = !this.check;
            this.$emit("input", this.check);
        },
    },
};
</script>

<style lang="less" scoped>
.cbx {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #9098a9;
  transition: all 0.2s ease;
}
.cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #409875;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}

.cbx:hover span:first-child {
  border-color: #409875;
}

.inp-cbx {
  position: absolute;
  opacity: 0;
}

.inp-cbx:focus {
  position: absolute;
  opacity: 0;
  bottom: 2px;
  left: -1px;
  height: 12px;
  width: 12px;
}

.inp-cbx:checked + .cbx span:first-child {
  background: #409875;
  border-color: #409875;
  // animation: wave 0.4s ease;
}


.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
</style>
<template>
  <SModal>
    <form ref="modal_window" class="d-flex d-col space-between" @submit.prevent="validate" v-if="page == 0">
      <div class="d-flex space-between align-center">
        <div class="font-18">
          {{ $t("modal.account.account") }}
        </div>
        <s-icon color="grey" @click.native="closeModal"> close </s-icon>
      </div>
      <div class="center mt-10 missingText red--text font-14">
        {{ message }}
      </div>
      <form ref="formAvatar" class="none">
        <input ref="fileAvatar" type="file" name="avatar" @change="upload" />
      </form>
      <section class="center">
        <div class="relative">
          <div class="profile">
            <img v-if="avatar" class="pic" :src="avatarUrl" />
            <s-icon v-if="!avatar" height="100"> account </s-icon>
            <pulse-loader :loading="load" color="white" size="10px" class="absolute" />
          </div>
          <button type="button" class="white round camera center opacity-60" @click="openPicker">
            <s-icon class=""> camera </s-icon>
          </button>
        </div>
      </section>
      <div class="grid align-center mt-20">
        <div class="font-14 opacity-60">
          {{ $t("modal.account.email") }}
        </div>
        <s-text-field v-model="email" disabled height="30" />
        <div class="font-14 opacity-60">
          {{ $t("modal.account.first_name") }}
        </div>
        <s-text-field v-model="firstName_input" height="30" />
        <div class="font-14 opacity-60">
          {{ $t("modal.account.last_name") }}
        </div>
        <s-text-field v-model="lastName_input" height="30" />
        <div class="font-14 opacity-60">
          {{ $t("modal.account.timezone") }}
        </div>
        <s-timezone v-model="timezone" />

        <div class="font-14 opacity-60">
          {{ $t("modal.account.current_password") }}
        </div>
        <s-text-field v-model="password" type="password" password height="30" />
        <div class="font-14 opacity-60">
          {{ $t("modal.account.new_password") }}
        </div>
        <div>
          <s-text-field v-model="newPassword" bar password height="30" type="password" />
        </div>
        <div class="font-14 opacity-60">
          {{ $t("modal.account.confirm_password") }}
        </div>
        <s-text-field v-model="confirmPassword" type="password" password height="30" />

        <div class="font-14 opacity-60">
          {{ $t("modal.account.region") }}
        </div>
        <s-dropdown v-model="preferedRegion" :height="30" class="w100 item" :items="servers" />
        <div class="font-14 opacity-60">
          {{ $t("modal.account.enable_tooltip") }}
        </div>
        <s-checkbox v-model="check" @click.native="check = !check" />
        <div v-if="enable2fa" class="font-14 opacity-60">
          {{ twoFAText }} {{ $t("modal.account.title") }}
        </div>
        <div v-if="enable2fa" class="row space-between align-center">
          <s-btn type="button" class="green" height="30" @click.native="twoFA()">
            {{ twoFAText }}
          </s-btn>
        </div>
      </div>
      <div class="d-flex justify-end mt-40">
        <s-btn type="button" height="36" class="mr-10" @click.native="closeModal">
          {{ $t("modal.cancel") }}
        </s-btn>
        <s-btn type="submit" height="36" class="green">
          {{ $t("modal.save") }}
        </s-btn>
      </div>
    </form>

    <form ref="modal_window" class="d-flex d-col space-between" @submit.prevent="enterDigits" v-if="page == 1">
      <div class="d-flex space-between align-center">
        <div class="font-18">{{ $t("modal.account.title") }}</div>
        <s-icon color="grey" @click.native="closeModal"> close </s-icon>
      </div>
      <div class="col mt-20 gap-10">
        <span>{{ $t("modal.account.subtitle") }} </span>
        <span class="weight-300 font-14">
          {{ $t("modal.account.description") }}
          <span class="weight-600"> {{ $t("modal.account.google") }}</span>
        </span>
        <img :src="qrImage" alt="" class="img mt-20" />
      </div>
      <div class="d-flex justify-end mt-40">
        <s-btn type="button" height="36" class="mr-10" @click.native="closeModal">
          {{ $t("modal.close") }}
        </s-btn>
        <s-btn type="button" height="36" class="mr-10 green" @click.native="enterDigits()">
          {{ $t("modal.next") }}
        </s-btn>
      </div>
    </form>

    <form ref="modal_window" class="d-flex d-col space-between" @submit.prevent="validateDigits" v-if="page == 2">
      <div class="d-flex space-between align-center">
        <div class="font-18">{{ $t("modal.account.title") }}</div>
        <s-icon color="grey" @click.native="closeModal"> close </s-icon>
      </div>
      <div class="col mt-20 gap-10">
        <span>{{ $t("modal.account.sixDigits") }} </span>
        <span class="weight-300 font-14">
          {{ $t("modal.account.barcode") }}
        </span>
      </div>
      <div class="center mt-40 missingText red--text font-14">
        {{ message }}
      </div>
      <div class="row pa-50 gap-10">
        <otp-input mode="group" :digits="6" @on-complete="onCompleteHandler" groupInputClass="separate-input-class" />
      </div>
      <div class="d-flex justify-end mt-40">
        <s-btn type="button" height="36" class="mr-10" @click.native="closeModal">
          {{ $t("modal.close") }}
        </s-btn>
        <s-btn type="button" height="36" class="mr-10 green" @click.native="validateDigits()">
          {{ $t("modal.next") }}
        </s-btn>
      </div>
    </form>

    <form ref="modal_window" class="d-flex d-col space-between" @submit.prevent="logout" v-if="page == 3">
      <div class="d-flex space-between align-center">
        <div class="font-18">{{ $t("modal.account.recoveryTitle") }}</div>
        <s-icon color="grey" @click.native="closeModal"> close </s-icon>
      </div>
      <div class="col mt-20 gap-20">
        <span>{{ $t("modal.account.recoverySubtitle") }} </span>
        <span class="font-14">{{ $t("modal.account.logoutTitle") }} </span>
        <div class="row border center w100 pa-20">
          <span class="weight-600 font-24 letter-spacing">{{ recoveryCode }}</span>
        </div>
        <span class="font-14 red--text">
          {{ $t("modal.account.recoveryWarning") }}
        </span>
      </div>

      <div class="d-flex justify-end mt-40">
        <s-btn type="button" height="36" class="mr-10" @click.native="closeModal">
          {{ $t("modal.close") }}
        </s-btn>
        <s-btn type="button" height="36" class="mr-10 green" @click.native="logout()">
          {{ $t("modal.account.logout") }}
        </s-btn>
      </div>
    </form>

  </SModal>
</template>

<script>
import { mapState } from "vuex";
import OtpInput from "otp-input-vue2/src/otp-input.vue";
export default {
  name: "AccountComp",
  components: {
    OtpInput
  },
  data() {
    return {
      firstName_input: "",
      lastName_input: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      message: "",
      timezone: "",
      check: true,
      preferedRegion: "",
      load: false,
      page: 0,
      qrImage: "",
      code: '',
      recoveryCode: ""
    };
  },
  methods: {
    async post() {
      let data = {
        firstName: this.firstName_input,
        lastName: this.lastName_input,
        timezone: this.timezone,
        tooltips: this.check == true ? "yes" : "no",
        preferredRegion: this.preferedRegion.value,
      };

      if (this.password.length) {
        data.oldPassword = this.password;
        data.newPassword1 = this.newPassword;
        data.newPassword2 = this.confirmPassword;
      }
      this.$store.commit("user/tip", this.check);
      let response = await this.$store.dispatch("user/setProfile", data);
      if (response.status == "ok") return this.closeModal();
      let message = this.$t("server." + response.errorCode);
      if (response.status == "error") this.message = message;
    },
    closeModal() {
      this.$store.commit("modals/accountModal", false);
    },
    onCompleteHandler(code) {
      this.code = code;
    },
    validatename() {
      let exr = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/;
      let first = this.firstName_input.match(exr);
      let last = this.lastName_input.match(exr);
      let code = "";
      if (!last) code = "invalid_lastname";
      if (!first) code = "invalid_firstname";
      if (this.lastName_input.length < 2) code = "min_last_name";
      if (this.firstName_input.length < 2) code = "min_first_name";
      if (this.lastName_input.length == 0) code = "enter_last_name";
      if (this.firstName_input.length == 0) code = "enter_first_name";
      if (!code) return true;
      this.message = this.$t("server." + code);
    },
    validate() {
      this.message = "";
      if (!this.validatename()) return;
      if (this.password.length > 0) {
        if (this.newPassword != this.confirmPassword) {
          this.message = this.$t("modal.account.password_no_match");
          return;
        }
        if (this.newPassword.length < 8) {
          this.message = this.$t("modal.account.password_min_8_char");
          return;
        }
      }
      this.post();
    },
    async validateDigits() {
      let code;
      if (this.code.length !== 6) return this.message = this.$t("modal.account.codeError");
      let res = await this.$store.dispatch("user/finish2fa", this.code);
      if (!res.recoveryCode) return this.message = this.$t("modal.account.2fa_auth_failed");
      if (typeof res.recoveryCode == 'string') {
        code = JSON.parse(res.recoveryCode)
      } else {
        code = res.recoveryCode
      
      }
      this.recoveryCode = code.at(0)
      this.page = 3;
    },
    logout() {
      this.$store.commit("modals/accountModal", false);
      this.$store.dispatch("user/logout");
      this.$store.dispatch("calendar/clearUpcomingEvents");
      this.$router.push("/login");
    },
    openPicker() {
      this.$refs.fileAvatar.click();
    },
    enterDigits() {
      this.page = 2;
    },
    async upload(data) {
      let formData = new FormData(this.$refs.formAvatar);
      this.load = true;
      await this.$store.dispatch("user/setProfilePicture", formData);
      await this.$store.dispatch("user/getUserInformation");
      this.load = false;
    },
    async twoFA(data) {
      if (this.user2FA?.has2FA) return this.$store.dispatch("user/cancel2fa");
      let res = await this.$store.dispatch("user/enable2fa");
      if (res.status !== "ok") return;
      this.page = 1;
      this.qrImage = res.data;
    },
  },
  computed: {
    twoFAText() {
      return this.user2FA.has2FA
        ? this.$t("modal.account.disable")
        : this.$t("modal.account.enable");
    },
    avatarUrl() {
      return process.env.VUE_APP_URL + "/api/avatar/" + this.avatar;
    },
    servers() {
      let data = [
        { display: this.$t("languages.na"), value: "na" },
        { display: this.$t("languages.eu"), value: "eu" },
      ];
      let ob = data.findIndex((el) => el.value == this.preferredRegion);
      data[ob].selected = true;
      return data;
    },
    ...mapState("user", [
      "email",
      "firstName",
      "lastName",
      "preferredRegion",
      "avatar",
      "tip",
      "enable2fa",
      "user2FA",
    ]),
  },
  mounted() {
    this.firstName_input = this.firstName;
    this.lastName_input = this.lastName;
    this.check = this.tip;
  },
};
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.account_modal {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: #243748;
}

.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
}

.strength_container {
  grid-column-gap: 4px;
  min-height: 2px;
  margin-top: 5px;
}

.strength {
  width: 72px;
  height: 2px;
  grid-column-gap: 5px;
}

.profile {
  background-color: grey;
  height: 100px;
  width: 100px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.camera {
  position: absolute;
  right: 0px;
  bottom: 4px;
  padding: 2px;
  transition: all 0.2s ease;
}

.camera:hover {
  transform: scale(1.1);
  opacity: 1;
}

.camera:focus {
  background-color: #ffffff;
  transform: scale(1.1);
  opacity: 1;
}

.red {
  background-color: #df5151;
}

.orange {
  background-color: #df9851;
}

.green {
  background-color: #55af6d;
}

.blue {
  background-color: #517cdf;
}

.img {
  max-height: 300px;
  min-height: 300px;
  height: 100%;
  object-fit: contain;
}

.confirmCode {
  border: rgba(255, 255, 255, 0.288) solid 1px;
  width: 100%;
  height: 50px;
  background: rgba(253, 253, 253, 0);
  text-align: center;
  color: white;
  font-size: 24px;
}

.border {
  border: 1px solid #9f9f9f;
}

.vue-otp-input>>>.separate-input-class {
  width: 100%;
  height: 48px;
  color: #fff;
  font-size: 24px;
  text-align: center;
}

@media only screen and (max-width: 576px) {
  .account_modal {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>
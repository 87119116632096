<template>
  <div :style="styling">
    <div class="input_text_field">
      <textarea
        ref="field"
        :style="inputStyling"
        :type="type"
        :disabled="disabled"
        :value="value"
        :placeholder="placeholder"
        onkeyup="this.setAttribute('value', this.value);"
        @focus="onFocus()"
        @blur="onBlur()"
        @input="update()"
      />
      <label>{{ label }}</label>
    </div>
    <div
      ref="line"
      class="line"
      :class="{ red: error }"
    />
    <span
      v-show="error"
      class="errorMessage"
    >{{ error }}</span>
  </div>
</template>

<script>
export default {
    props: [
        "error",
        "label",
        "value",
        "type",
        "details",
        "block",
        "placeholder",
        "height",
        "disabled",
        "minHeight"
    ],
    computed: {
        styling: function () {
            let ob = {};
            if (this.block == "" || this.block == "true") ob.width = "100%";
            if (this.details == "" || this.details == "true") ob.minHeight = "80px";
            if (this.label) if (this.label.length > 0) ob.paddingTop = "20px";
            return ob;
        },
        inputStyling: function () {
            let ob = {};
            if (this.height) ob.height = this.height + 'px';
            if (this.minHeight ) ob.minHeight = this.minHeight + 'px';
            return ob;
        },
    },
    mounted() {
        if (this.value) this.$refs.field.setAttribute("value", this.value);
    },
    methods: {
        onFocus() {
            this.$refs.line.style.width = "100%";
        },
        onBlur() {
            if (!this.error) this.$refs.line.style.width = "0";
        },
        update() {
            this.$emit("input", this.$refs.field.value);
        },
    },
};
</script>



<style scoped>
.input_text_field {
  position: relative;
  display: flex;
  align-items: center;
}

label {
  font-size: 13px;
  position: absolute;
  transition: all ease 0.2s;
  left: 10px;
  pointer-events: none;
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
}

.icon {
  position: absolute;
  right: 10px;
}

.line {
  width: 0;
  height: 1px;
  background-color: #409875;
  transition: all ease 0.2s;
}

.red {
  background-color: #df5151;
}

.errorMessage {
  font-size: 12px;
  color: #df5151;
  margin-left: 8px;
}

textarea {
  height: 40px;
  background-color: hsla(0, 0%, 0%, 0.35);;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.75);
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
  font-family: roboto;
}

textarea:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

textarea:focus + label {
  transform: translateY(-28px);
  left: 8px;
  font-size: 10px;
}

textarea[value]:not([value=""]) + label {
  transform: translateY(-28px);
  left: 8px;
  font-size: 10px;
}

textarea:focus {
  background-color: #151b23;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.95);
  outline: none;
}

textarea:focus::placeholder {
  color: rgba(255, 255, 255, 0.55);
}

textarea::placeholder {
  color: rgba(255, 255, 255, 0.50);
  font-size: 12px;
}

textarea:disabled {
  background-color: rgba(129, 129, 129, 0.0);
}
</style>

import router from "../router/index";
import store from "../store/index";

export const auth = function (response) {
    if (!response) return true;
    if (router.currentRoute.name === "Login") return true;
    if (response.errorCode !== "authentication_error") return true;
    if (!store.state.user.email) return true;
    store.commit("user/clear");
    router.push("/login");
    return false;
};

const header = { "Content-type": "application/json; charset=UTF-8" };

export const get = async function (url) {
    url = process.env.VUE_APP_URL + url;
    let response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: header,
    });
    response = await response.json();
    auth(response);
    return response;
};

export const post = async function (url, data) {
    url = process.env.VUE_APP_URL + url;
    let response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: header,
    });
    if (!response.ok) return response
    response = await response.json();
    auth(response);
    return response;
};

export const del = async function (url) {
    url = process.env.VUE_APP_URL + url;
    let response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
        headers: header,
    });
    response = await response.json();
    auth(response);
    return response;
};
export const validateEmail = function (email) {
    return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email);
};

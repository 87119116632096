<template>
  <div
    ref="modal_page"
    class="modal_page center"
  >
    <form
      ref="modal_window"
      class="modal_window d-flex d-col space-between"
      @submit.prevent="post"
    >
      <div class="d-flex space-between align-center">
        <div class="font-18">
          {{ $t('modal.appInfo.heading') }}
        </div>
        <s-icon
          color="grey"
          @click.native="closeModal"
        >
          close
        </s-icon>
      </div>
      <div class="body pt-20">
        <div class="center py-20">
          <img
            class="logo"
            src="/img/static/logo-landing.svg"
            alt=""
          >
        </div>

        <div class="item">
          {{ $t('modal.appInfo.version') }}: {{ version }}
        </div>

        <div class="item">
          {{ $t('modal.appInfo.build') }}: {{ hash }}
        </div>

        <div class="item">
          {{ $t('modal.appInfo.sales') }}:
          <a
            target="_blank"
            href="mailto:sales@securmeet.com"
          >sales@securmeet.com</a>
        </div>

        <div class="item">
          {{ $t('modal.appInfo.support') }}:
          <a
            target="_blank"
            href="mailto:support@securmeet.com"
          >support@securmeet.com</a>
        </div>

        <div class="item">
          {{ $t('modal.appInfo.abuse') }}:
          <a
            target="_blank"
            href="mailto:abuse@securmeet.com"
          >abuse@securmeet.com</a>
        </div>

        <div class="item">
          2021 <s-icon
            color="white"
            height="16"
          >
            copyright
          </s-icon> securCom
          Inc.
        </div>
      </div>

      <div class="d-flex justify-end mt-40">
        <s-btn
          type="button"
          height="36"
          class="green"
          @click.native="closeModal"
        >
          {{ $t('modal.close') }}
        </s-btn>
      </div>
    </form>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
    data() {
        return {
            hash: process.env.VUE_APP_GIT_HASH,
            version: process.env.VUE_APP_VERSION,
        };
    },
    computed: {},
    mounted() {
        gsap.from(this.$refs.modal_page, {
            opacity: 0,
            duration: 0.3,
            ease: "power2.out",
        });

        gsap.from(this.$refs.modal_window, {
            scale: 0.7,
            duration: 0.3,
            ease: "power2.out",
        });
    },
    methods: {
        post() {
            this.closeModal();
        },
        closeModal() {
            gsap.to(this.$refs.modal_page, {
                opacity: 0,
                duration: 0.3,
                ease: "power2.out",
            });
            gsap.to(this.$refs.modal_window, {
                scale: 0.7,
                duration: 0.3,
                ease: "power2.out",
                onComplete: () => this.$store.commit("modals/appInfo", false),
            });
        },
    },
};
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.modal_window {
  width: 450px;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(61, 16, 16, 0.35);
  background-color: #243748;
}

a {
  color: white;
  text-decoration: underline;
}

.item {
  padding: 10px 0;
}

.logo {
  width: 100%;
  max-width: 300px;
}

@media only screen and (max-width: 576px) {
  .modal_window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import(`../views/Welcome.vue`),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("../views/Register.vue"),
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/Login.vue"),
    },
    {
        path: "/lostpassword",
        name: "LostPassword",
        component: () => import("../views/LostPassword.vue"),
    },
    {
        path: "/terms-of-service",
        name: "TOS",
        component: () => import("../views/TOS.vue"),
    },
    {
        path: "/download",
        name: "Download",
        component: () => import("../views/Download.vue"),
    },
    {
        path: "/faq",
        name: "FAQ",
        component: () => import("../views/FAQ.vue"),
    },
    {
        path: "/resetpassword/:hash",
        name: "ResetPassword",
        component: () => import("../views/ResetPassword.vue"),
    },
    {
        path: "/now/:id",
        name: "Now",
        component: () => import("../views/Meeting.vue"),
    },
    {
        path: "/calendar",
        redirect: "/calendar/week/date",
        name: "Calendar",
    },
    {
        path: "/calendar/:view",
        name: "CalendarDetails",
        redirect: "/calendar/week/date",
    },
    {
        path: "/calendar/:view/:date",
        name: "CalendarDate",
        component: () => import("../views/Calendar.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/schedule/:edit",
        name: "ScheduleEdit",
        component: () => import("../views/Schedule.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/schedule",
        name: "Schedule",
        component: () => import("../views/Schedule.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/rooms",
        name: "Rooms",
        component: () => import("../views/Rooms.vue"),
        meta: {
            requiresAuth: true,
            blockUserRoll: false
        },
    },
    {
        path: "/attendees",
        name: "Attendees",
        component: () => import("../views/Attendees.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/rate",
        name: "Rate",
        component: () => import("../views/RateExperience.vue"),
    },
    {
        path: "*",
        component: () => import("../views/404.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    let login = from.name == "Login";
    let register = from.name == "Register";
    let dashboard = to.name == "Home";
    let allowUser = to.meta.blockUserRoll;
    store.commit("user/routerHistory", to.name);

    if ((login || register) && dashboard) {
        let counter = 0;
        let intervalID = setInterval(() => {
            if (store.state.user.email) {
                clearInterval(intervalID);
                return next();
            }
            if (++counter > 1000) clearInterval(intervalID);
        }, 10);
        return;
    }

    if (allowUser && store.state.user.role == 'user') return next({ name: "Home" });
    if (!to.meta.requiresAuth) return next();
    if (!store.state.user.email) return next({ name: "Login" });
    if (store.state.user.email) return next();
    return next();
});

export default router;

<template>
    <SModal>
        <form class="col " @submit.prevent="post">
            <div class="d-flex space-between align-center">
                <div class="font-18">{{ $t("modal.account.title") }}</div>
                <s-icon color="grey" @click.native="closeModal"> close </s-icon>
            </div>
            <div class="col mt-20 gap-10">
                <span>{{ $t("modal.account.auth2FAcode") }} </span>
                <span class="weight-300 font-14">
                    {{ $t("modal.account.authMain") }}
                </span>
            </div>
            <div class="center mt-40 missingText red--text font-14">
                {{ message }}
            </div>
            <div class="row pa-50 gap-10">
                <otp-input mode="group" :digits="6" @on-complete="onCompleteHandler" groupInputClass="separate-input-class" />
            </div>
            <div class="d-flex justify-end mt-40">
                <s-btn type="button" height="36" class="mr-10" @click.native="closeModal">
                    {{ $t("modal.close") }}
                </s-btn>
                <s-btn type="submit" height="36" class="mr-10 green">
                    {{ $t("modal.verify") }}
                </s-btn>
            </div>
        </form>
    </SModal>
</template>
  
<script>
import OtpInput from "otp-input-vue2/src/otp-input.vue";
import { mapState } from "vuex";
export default {
    components: {
        OtpInput
    },
    data() {
        return {
            message: "",
            code: ""
        }
    },
    methods: {
        async post() {
            if (this.code.length !== 6) return this.message = this.$t("modal.account.codeError");
            let res = await this.$store.dispatch("user/check2fa", this.code);
            if (res.status == 401) return this.message = this.$t("modal.account.wrongCode");
            if (res.status == "error") return this.message = this.$t("modal.account.wrongCode");
            let resLogin = await this.$store.dispatch("auth/login", this.cred);
            if (resLogin.status == 'error') this.message = resLogin.message
            await this.$store.dispatch("user/getUserInformation");
            this.$store.commit("calendar/loadData");
            this.$store.dispatch("rooms/getRoomsInformation");
            this.$store.dispatch("attendees/getAttendeesInformation");
            this.$store.dispatch("user/getTimezones");
            this.$store.dispatch("user/checkIfAccountIsOutOfTrial");
            this.$store.dispatch("calendar/innitGetUpcomingEvents");
            this.$store.dispatch("user/getConfiguration");
            this.$router.push("/");
            this.$store.commit("user/cred", {});
            this.closeModal();

        },
        closeModal() {
            this.$store.commit("modals/auth2fa", false);
        },
        onCompleteHandler(code) {
            this.code = code;
        }
    },
    computed: {
        ...mapState("user", ["cred", "enable2fa", "user2FA"])
    }

};
</script>
  
<style scoped>
.border {
    border: 1px solid #9f9f9f;
}

.vue-otp-input>>>.separate-input-class {
    width: 100%;
    height: 48px;
    color: #fff;
    font-size: 24px;
    text-align: center;
}
</style>